@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: 100% !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.sc-printable-container {
  background: #fff;
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }

  .sc-printable-container {
    background: #fff;
    height: 100vh;
    width: 100vw;
  }
}

@page {
  margin: 10mm;
  size: auto;
}
