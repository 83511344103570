.complaints__container {
  --sc-container-body-background-color: #E3EAF2;
}

.complaint__container-background {
  background: var(--sc-report-summary-background-color, #fff);
}

.page-phoneReport__complaints__complaint-item .sc-tag {
  bottom: 0.125rem;
  position: relative;
}

.page-complaints__sc-tooltipContainer {
  color: var(--sc-section-title-tooltip-color);
}

.page-complaints__modal-spotlight-width {
  width: 12.875rem;
}
