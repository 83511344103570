.id-protect-double-icon_container .id-protect-double-icon_img-container {
  position: relative;
}

.id-protect-double-icon_container svg {
  left: 75%;
  position: absolute;
  top: 80%;
  transform: translate(0, -50%);
}
