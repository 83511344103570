.page-reset-password {
  background-color: var(--sc-page-background, #fff);
}

.page-reset-password-error__width {
  margin: 0 auto;
  width: 15rem;
}

.page-reset-password-recaptcha {
  scale: 0.789;
}
