.page-new-password {
  background-color: var(--sc-page-background, #fff);
}

.page-new-password-error__max-width {
  margin: 0 auto;
  width: 15rem;
}

.set-width {
  width: 15rem;
}
