.auto-report__spotlight {
  width: 100%;
}

ul {
  margin: 0;
}

.page-auto-report__title-brands__svg {
  display: inline !important; /* override element block style for svg */
  vertical-align: middle;
}
