.pf-theme .pf-most-recent-report__container .sc-container__body {
  border-radius: 16px;
  background-color: #F7DF66;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.pf-most-recent-report__inner-container {
  position: relative;
}

.pf-most-recent-report__header {
  max-width: calc(100% - 80px);
}

/* content container */
.pf-most-recent-report__content {
  position: relative;
}

.pf-most-recent-report__content__container {
  position: absolute;
  bottom: 22px;
}

.pf-most-recent-report__content__container--right {
  right: 0;
}

.pf-most-recent-report__content__icon-container {
  position: absolute;
  bottom: 22px;
  right: 0;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 3;
}

.pf-most-recent-report__content__image {
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 2;
}

.pf-most-recent-report__content__image--placeholder {
  position: relative;
  z-index: 2;
}

.pf-most-recent-report__pin {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}


/* Data Containers */
.pf-most-recent-report__content__data-container {
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 3;
}

.pf-most-recent-report__content__data-container--left {
  align-self: flex-start;
}

.pf-most-recent-report__content__data-container--right {
  align-self: flex-end;
}

.pf-most-recent-report__content-data-container--pill {
  border-radius: 99px;
}

#pf-most-recent-report__content__data-container--owners {
  min-width: 100px;
}

/* Background Icons */
.pf-most-recent-report__background-icon {
  position: absolute;
  z-index: 1;
}

.pf-most-recent-report__background-icon--top {
  top: 0;
  right: 0;
}

.pf-most-recent-report__background-icon--bottom {
  bottom: 98px;
  left: -16px;
  transform: rotate(180deg);
}

@media screen and (min-width: 960px) {
  .pf-most-recent-report__background-icon--bottom {
    bottom: 0px;
    left: 0;
  }

  .pf-most-recent-report__button {
    align-self: center;
  }

  .pf-most-recent-report__content__image {
    height: 322px;
  }
}
