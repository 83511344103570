.page-neighborhood-search__popup-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.mapboxgl-popup-content {
  width: 259px;
}

.mapboxgl-popup-content .page-neighborhood-search__popup-container {
  overflow: hidden;
  width: 100%;
}
