.page-my-account__membership-information__icon {
  align-items: center;
  color: var(--sc-text-color-primary);
  display: flex;
  justify-content: center;
  min-width: 32px;
}

.pf-theme .page-my-account__membership-information__icon {
  color: var(--sc-text-color-clickable);
}

.page-my-account__id-protect__icon {
  align-items: center;
  color: var(--sc-id-protect-header-color);
  display: flex;
  justify-content: center;
  min-width: 32px;
}

.page-my-account__id-protect__header {
  color: var(--sc-id-protect-header-color);
}

.page-my-account__id-protect__container {
  background-color: var(--sc-id-protect-background-color);
}

.page-my-account__contact-support {
  background-color: #fff;
}

.page-my-account__membership-information__icon--download {
  color: var(--sc-text-color-accent);
  min-width: 40px;
}

.pf-theme .page-my-account__membership-information__icon--download {
  color: var(--sc-text-color-base);
}

.page-my-account__id-protect__content {
  max-width: 727px;
}

.page-my-account__spotlight-link:hover {
  text-decoration: underline;
}
