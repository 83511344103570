.page-dashboard__membership-information__icon {
  align-items: center;
  color: var(--sc-text-color-primary);
  display: flex;
  justify-content: center;
  min-width: 32px;
}

.page-dashboard__monitoring__add-monitoring {
  color: var(--sc-text-color-primary);
  height: 141px;
  /* hardcoded to match the hardcoding of the monitoringContainer comp (70px - 32px) */
}

.page-dashboard_multisearch-spacer_spotlight-content {
  box-sizing: border-box;
  height: 100%;
}

.page-dashboard_multisearch-spacer_spotlight-content_layout {
  height: 100%;
}

.page-dashboard .sc-monitoring-container {
  min-width: 0;
  width: 100%;
}

.page-dashboard .sc-monitoring-container .sc-container-line-item {
  width: 100%;
}

.page-dashboard .sc-monitoring-container .sc-report-link {
  min-width: 0;
}

.page-dashboard .dashboard__monitoring-container__title svg {
  flex-shrink: 0;
}
