.page-id-protect-sub-footer {
  width: 100%;
}

.page-id-protect {
  min-height: unset;
}

.page-id-protect-select-button--desktop {
  max-width: 24rem;
  width: 24rem;
}

.page-id-protect-large-text--desktop {
  font-size: 2.625rem;
}

.page-id-protect-small-text--desktop {
  font-size: 1.3125rem;
}

.page-id-protect_policy--desktop {
  max-width: 47.8125rem;
}

.page-id-protect__underline {
  border-bottom: 1px white solid;
}

.id-protect-icon-color {
  color: var(--sc-id-protect-doubleIcon-color);
}

.page-id-protect-select-button--mobile button {
  height: unset;
  min-height: 2.375rem;
  text-wrap: balance;
}

.page-idprotect-text-subtle-id-protect {
  color: var(--sc-text-color-subtle-id-protect);
}

.page-id-protect-accent1-text {
  color: var(--sc-text-color-accent1-id-protect);
}

.page-id-protect-accent2-text {
  color: var(--sc-text-color-accent2-id-protect);
}

.page-id-protect-testimonial-text {
  color: var(--sc-text-color-testimonial-id-protect);
}
