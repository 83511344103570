@media screen and (width >= 960px) {
  .page-neighborhood-report__left-column {
    min-width: 23.375rem;
  }
}

.neighborhood-report__monitoring-modal__anchorlink {
  align-self: center;
}

.page-neighborhood-report__popup-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.mapboxgl-popup-content {
  width: 259px;
}

.mapboxgl-popup-content .page-neighborhood-report__popup-container {
  overflow: hidden;
  width: 100%;
}
