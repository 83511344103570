.report-teaser {
  text-align: center;
}

.report-teaser .page-dashboard__left-column {
  flex: 1;
}

.report-teaser img {
  height: auto;
  object-fit: contain;
  width: 100%;
}

.report-teaser__map {
  height: auto;
  width: 100%;
}

.report-teaser__map--loading {
  height: auto;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 100%;
}

@media screen and (width >= 960px) {
  .report-teaser__map {
    height: auto;
  }

  .report-teaser img {
    height: 27.625rem;
    max-height: 14.875rem;
  }

  .report-teaser__map--loading {
    height: 27.625rem;
    max-height: 14.875rem;
  }
}
