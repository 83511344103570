.page-not-supported {
  background-color: var(--sc-page-background--login, #f2b415);
}

.page-not-supported .sc-sheet {
  text-align: center;
  width: 100%;
}

.page-not-supported .sc-sheet:nth-of-type(2) {
  margin-top: 12.5dvh;
}

.page-not-supported-sheet-form-container {
  margin: 0 auto;
  max-width: 62.5rem;
}
