.timeline {
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.timeline::before {
  background: #0c275d;
  content: "";
  height: calc(100% - 2.6rem) !important;
  left: 21.5%;
  position: absolute;
  top: 8px;
  width: 1px;
}

.timeline ul {
  padding-left: 0;
}

.timeline ul li {
  box-sizing: border-box;
  list-style: none;
  position: relative;
  width: 245px;
}

.sale-date {
  color: var(--sc-container-head-background-color, #ee0de5);
}

.owner {
  border-top: 1px solid var(--sc-collapse-item-list-border-color, #c9cbd4);
}

@media (width >= 768px) {
  .timeline ul li {
    width: 54%;
  }
}

.timeline-bullet {
  position: relative;
}

.finance-block {
  border-left: var(--sc-container-head-background-color, #ee0de5) solid 4px;
}

.content {
  background-color: var(--global-summary-background, #f5f5f5);
}

.sale-header {
  background-color: var(--sc-container-head-background-color, #ee0de5);
  border-radius: var(--radius-edge, 4px) var(--radius-edge, 4px) 0 0;
  color: var(--sc-container-body-background-color, #fff);
}

.timelineContainer .sc-grid {
  display: grid;
  grid-template-columns: 19% 5% 76%;
}
