.page-login {
  background-color: var(--sc-page-background--login, #f2b415);
}

.page-login .sc-sheet {
  text-align: center;
  width: 100%;
}

.page-login .sc-sheet:nth-of-type(2) {
  margin-top: 12.5dvh;
}

.page-login-sheet-form-container {
  margin: 0 auto;
  max-width: 28.5rem;
}

.page-login .sc-container__body {
  background-color: var(--sc-container-body-background-color-login, #fff);
}

/* for pis, disabled color is the same as the background container. For other brands the border will be transparent */
.page-loign__button-border .sc-button--md.sc-button--primary:disabled {
  border: var(--sc-button-border-color-disabled, transparent) solid 1px;
}

.pf-theme .page-login .page-login__container>.sc-container__body {
  border-radius: 16px;
  background-color: #F7DF66;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.pf-theme .page-login .sc-anchor-link-color-primary,
.pf-theme .sc-minFooter .sc-anchor-link-color-primary {
  color: #181819;
}
