.page-people-search__relative-text-line {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@media screen and (width >= 960px) {
  .page-people-search__view-report-button {
    justify-self: flex-end;
  }
}

.page-people-search__ordered-list {
  margin: 0;
  padding-left: 2rem;
}
