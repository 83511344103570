.neighborhood-inputs__search-container .sc-input-container {
  width: 100%;
}

.neighborhood-inputs__search-type__radio-button-container {
  cursor: pointer;
}

.neighborhood-inputs__search-type__radio-button {
  appearance: none;
  appearance: none;
  background: var(--sc-container-body-background-color-alt) no-repeat center
    center;
  border: 2.5px solid var(--sc-container-body-background-color-alt);
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

.neighborhood-inputs__search-type__radio-button:checked {
  background: var(--sc-input-color-text) no-repeat center center;
}
