.page-cancel-offer {
  background-color: var(--sc-page-background, #fff);
}

.page-cancel-offer .pf-theme .sc-button-container {
  max-width: 15.5rem;
}

.page-cancel-offer_ul {
  margin: 0;
}
