.score-ellipse {
  background-color: var(--sc-button-background-color);
  border-radius: 50%;
  cursor: pointer;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  width: 48px;
}

.pf-theme .score-ellipse {
  background-color: #2879A8;
}

.score-ellipse.disabled {
  background-color: #c9cbd4;
  cursor: auto;
}

.score-content {
  place-content: center center;
  display: flex;
  height: 100%;
  width: 100%;
}

.actual-score {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
  margin-top: 13px;
}

.out-of-score {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-top: 19px;
}

.rating-label {
  font-size: 11px;
  margin-top: 2px;
  text-align: center;
}
