.page {
  min-height: 100dvh;
}

/* Our global css is overriding some of the css set from SiteJabber. We need this here to make sure that doesnt happen */
.stjr-container [class*=" custom-icon-"]:before
{
  font-size: unset;
  color: unset;
  background-color: unset;
}
